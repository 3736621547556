<template>
  <v-container
    fluid
    class="visit-listing white-background main-listing-page pb-0"
  >
    <v-tabs
      active-class="custom-tab-active"
      v-model="visitTab"
      background-color="transparent"
      color="cyan"
      class="custom-tab-transparent tab-sticky"
    >
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#all"
      >
        <template v-if="counts.all > 0">
          <v-badge bordered color="orange darken-4" :content="counts.all">
            All
          </v-badge>
        </template>
        <template v-else>All</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#overdue"
      >
        <template v-if="counts.overdue > 0">
          <v-badge bordered color="orange darken-4" :content="counts.overdue">
            Overdue
          </v-badge>
        </template>
        <template v-else>Overdue</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#today"
      >
        <template v-if="counts.today > 0">
          <v-badge bordered color="orange darken-4" :content="counts.today">
            Today
          </v-badge>
        </template>
        <template v-else>Today</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#tomorrow"
      >
        <template v-if="counts.tomorrow > 0">
          <v-badge bordered color="orange darken-4" :content="counts.tomorrow">
            Tomorrow
          </v-badge>
        </template>
        <template v-else>Tomorrow</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#this-week"
      >
        <template v-if="counts.this_week > 0">
          <v-badge bordered color="orange darken-4" :content="counts.this_week">
            This Week
          </v-badge>
        </template>
        <template v-else>This Week</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#upcomming"
      >
        <template v-if="counts.upcomming > 0">
          <v-badge bordered color="orange darken-4" :content="counts.upcomming">
            Upcoming
          </v-badge>
        </template>
        <template v-else>Upcoming</template>
      </v-tab>
      <v-tab
        :disabled="pageLoading"
        class="font-size-16 font-weight-600 px-8"
        href="#completed"
      >
        <template v-if="counts.completed > 0">
          <v-badge bordered color="orange darken-4" :content="counts.completed">
            Completed
          </v-badge>
        </template>
        <template v-else>Completed</template>
      </v-tab>
      <v-tabs-items v-model="visitTab">
        <v-tab-item value="all">
          <DeliveryListingTemplate
            :is-admin="isAdmin"
            visitType="all"
          ></DeliveryListingTemplate>
        </v-tab-item>
        <v-tab-item value="overdue">
          <DeliveryListingTemplate
            :is-admin="isAdmin"
            visitType="overdue"
          ></DeliveryListingTemplate>
        </v-tab-item>
        <v-tab-item value="today">
          <DeliveryListingTemplate
            :is-admin="isAdmin"
            visitType="today"
          ></DeliveryListingTemplate>
        </v-tab-item>
        <v-tab-item value="tomorrow">
          <DeliveryListingTemplate
            :is-admin="isAdmin"
            visitType="tomorrow"
          ></DeliveryListingTemplate>
        </v-tab-item>
        <v-tab-item value="this-week">
          <DeliveryListingTemplate
            :is-admin="isAdmin"
            visitType="this-week"
          ></DeliveryListingTemplate>
        </v-tab-item>
        <v-tab-item value="upcomming">
          <DeliveryListingTemplate
            :is-admin="isAdmin"
            visitType="upcomming"
          ></DeliveryListingTemplate>
        </v-tab-item>
        <v-tab-item value="completed">
          <DeliveryListingTemplate
            :is-admin="isAdmin"
            visitType="completed"
          ></DeliveryListingTemplate>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DeliveryListingTemplate from "@/view/pages/visit/Visit-Listing-Template";

export default {
  name: "visit",
  mounted() {
    const route_name = this.$route.name;
    const indexd = route_name.indexOf("admin");
    if (indexd >= 0) {
      this.isAdmin = true;
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Delivery" }]);
  },
  data() {
    return {
      isAdmin: false,
      pageLoading: false,
      // visitTab: null,
      counts: {
        all: 0,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        this_week: 0,
        upcomming: 0,
        completed: 0,
      },
    };
  },
  methods: {
    getDeliveryCounts(countObject) {
      const _this = this;
      if (_this.lodash.isEmpty(countObject) === false) {
        _this.counts = {
          all: _this.lodash.toSafeInteger(countObject.all),
          overdue: _this.lodash.toSafeInteger(countObject.overdue),
          today: _this.lodash.toSafeInteger(countObject.today),
          tomorrow: _this.lodash.toSafeInteger(countObject.tomorrow),
          this_week: _this.lodash.toSafeInteger(countObject.thisWeek),
          upcomming: _this.lodash.toSafeInteger(countObject.upcomming),
          completed: _this.lodash.toSafeInteger(countObject.completed),
        };
      }
    },
  },
  components: {
    DeliveryListingTemplate,
  },
  created() {
    if (this.$route.query && this.$route.query.tab) {
      this.visitTab = this.$route.query.tab;
    }
  },
  computed: {
    visitTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.visitTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "all";
      },
    },
  },
};
</script>
